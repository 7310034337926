import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { LocalStorageService } from './storage-service/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { NAVIGATE_ROUTES } from '../global/constants';
import { ApiService } from './api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class UserDetailsService {
    getToken: any;
    decrypt: any;
    userId: any;
    encryptUserId: any;
    userImg: any;
    updateUserImg = new BehaviorSubject<any>({});
    updateUserName = new BehaviorSubject<any>({});
    updateCompanyList = new BehaviorSubject<any>('');
    decryptSelectedCompanyId: any;
    apiEndPoint = NAVIGATE_ROUTES;
    res: any = [];
    userRolePermission: any = {};

    constructor(private ls: LocalStorageService, private apiService: ApiService) {
        this.getToken = localStorage.getItem('token');
        this.encryptUserId = this.getToken;
        if (this.getToken) {
            this.decrypt = CryptoJS.AES.decrypt(this.getToken?.trim(), '').toString(
                CryptoJS.enc.Utf8
            );
        }
        this.userId = parseInt(this.decrypt);
    }
    async getUserName() {
        return await this.ls.getDataFromIndexedDB('username');
    }

    async getCompanyId() {
        return await this.ls.getDataFromIndexedDB('companyId');
    }

    async getUserImagePath() {
        return await this.ls.getDataFromIndexedDB('userimagepath');
    }

    async getUserEmail() {
        return await this.ls.getDataFromIndexedDB('email');
    }
    async getUserMobileNumber() {
        return await this.ls.getDataFromIndexedDB('mobileNumber');
    }

    async editUser(newUserImgPath: any) {
        await this.updateUserImg.next(newUserImgPath);
    }

    async getSelectedCompanyId() {
        return await this.ls.getDataFromIndexedDB('selectedCompanyId');
    }

    async getSelectedCompanyName() {
        return await this.ls.getDataFromIndexedDB('selectedCompanyName');
    }

    async editUserName(newUserName: any) {
        await this.updateUserName.next(newUserName);
    }

    async getUpdatePlanState() {
        return await this.ls?.getDataFromIndexedDB('updateState');
    }

    async getUpdatedCompanyList(boolean: boolean) {
        await this.updateCompanyList.next(boolean);
    }

    async getUserRolePermissions() {
        return await this.ls?.getDataFromIndexedDB('userRolePermission');
    }

    async getUserRolePermission(userId: any, companyId: any) {
        this.res = [];
        let userRolePermission: any = {};
        if (companyId) {
            const url = `${this.apiEndPoint.COMPANY}/userCompanyAccesses?companyId=${companyId}&userId=${userId}`;
            await this.apiService.get(url).then(async (data: any) => {
                if (data) {
                    userRolePermission = data?.role?.rolePermission
                        ? data?.role?.rolePermission
                        : {};
                    userRolePermission['permissions'] = data?.permissions;
                    userRolePermission['memberId'] = data?.member_id;
                    userRolePermission['superAdmin'] = data?.superAdmin;
                    userRolePermission['owner'] = data?.owner;
                    await this.ls.setDataInIndexedDB(
                        'userRolePermission',
                        userRolePermission
                    );
                }
            });
            return userRolePermission;
        }
    }

    async getSelectedCompanyData() {
        return this.ls?.getDataFromIndexedDB('selectedCompanyData');
    }

    async getLoginUserData() {
        return this.ls?.getDataFromIndexedDB('loginUserData');
    }
}
