import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { priority_name, status_name } from 'src/app/shared/global/constants';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
    selector: 'app-create-sub-task',
    templateUrl: './create-sub-task.component.html',
    styleUrls: ['./create-sub-task.component.scss'],
})
export class CreateSubTaskComponent implements OnInit {
    taskDetailForm!: FormGroup;
    subtaskFormArr: any = [];
    subTaskArr: any;
    selectedCompanyId: any;
    userId: any;
    toggleValue = false;

  @Input() maxDateProject: any;
  @Input() minDateProject: any;

  @Input() statusList: any;
  @Input() addSubTask: any;
  @Input() priorityList: any;
  @Input() isTimeSheetSetting: any;
  @Input() data: any;
  @Input() subtaskDetails: any;
  @Output('subTaskData') subTaskData = new EventEmitter();
  @Input() quickReplyList: any;
  @Input() coMembersList: any;
  subscription: Subscription;
  quickReplyFilter: any = [];
  isEnableDropdown: any = [];
  constructor(
    public formBuilder: FormBuilder,
    public userDetailService: UserDetailsService,
    private taskService: TasksService,
    private toastrService: ToastrService
  ) {}

  ngOnChanges(change: any) {
      this.isEnableDropdown.isSubTaskEnable = [];
      if (change?.addSubTask?.currentValue > change?.addSubTask?.previousValue) {
          this.addMultipleSubTask();
      }    
  }

  async ngOnInit() {
      this.isEnableDropdown.isCheckListEnable = [];
      this.taskDetailFormInit();
      this.setMainTaskAssignee();
      this.userId = await this.userDetailService.encryptUserId;
      this.selectedCompanyId =
      await this.userDetailService.getSelectedCompanyId();
      this.subscription = this.taskService.subTaskDetailsData.subscribe(
          async (data: any) => {
              this.subtaskFormArr = [];
              if (data?.data?.length > 0 && this.data && !data.isCheckListSubTask) {
                  this.subtaskFormArr = this.taskDetailForm.controls[
                      'subTask'
                  ] as FormArray;
                  await data.data.forEach(async (data: any, index: number) => {
                      await this.subtaskFormArr.push(
                          this.formBuilder.group({
                              _id: data._id,
                              subTaskName: data?.task_name,
                              subTaskDueDate: new Date(data.due_date),
                              subTaskStartDate: data.start_date
                                  ? new Date(data.start_date)
                                  : '',
                              subTaskPriority: this.priorityList.filter(
                                  (ele: any) => ele._id === data.priority._id
                              )[0],
                              subTaskStatus: data.status,
                              subTaskAssignee: [],
                              subTaskGroup: [],
                          })
                      );
                      const selectedId = {
                          groups: data.groups
                              ? data.groups.map((v: any) => {
                                  return v.id;
                              })
                              : [],
                          assignees: data.assignees
                              ? data.assignees.map((v: any) => {
                                  return v.id;
                              })
                              : [],
                      };
                      const myForm: any = (<FormArray>(
              this.taskDetailForm.get('subTask')
            )).at(index);
                      myForm.controls['subTaskGroup'].patchValue([...selectedId.groups]);
                      myForm.controls['subTaskAssignee'].patchValue([
                          ...selectedId.assignees,
                      ]);
                  });
              }

              if (data?.data?.length > 0 && data.isCheckListSubTask) {
                  this.subtaskFormArr = this.taskDetailForm.controls[
                      'subTask'
                  ] as FormArray;
                  data.data.forEach(async (ele: any) => {
                      await this.subtaskFormArr.push(
                          this.formBuilder.group({
                              _id: ele._id,
                              subTaskName: [ele.subTaskName, [Validators.required]],
                              subTaskDueDate: ['', [Validators.required]],
                              subTaskStartDate: [''],
                              subTaskPriority: [
                                  this.priorityList.filter(
                                      (priority: any) => priority._id === ele.subTaskPriority._id
                                  )[0],
                              ],
                              subTaskStatus: [
                                  this.statusList.filter(
                                      (status: any) => status.id === ele.subTaskStatus.id
                                  )[0],
                              ],
                              subTaskAssignee: ['', [Validators.required]],
                              subTaskGroup: ['', [Validators.required]],
                          })
                      );
                  });
                  this.taskDetailForm.updateValueAndValidity();
                  if (this.toggleValue) {
                      await this.setMainTaskAssignee();
                  }
                  if (this.toggleValue) {
                      await this.setMainTaskAssignee();
                  }                                
                  this.sendSubTaskData();
              }
          }
      );
  }

  get subTasksList() {
      return this.taskDetailForm.get('subTask') as FormArray;
  }

  taskDetailFormInit() {
      this.taskDetailForm = this.formBuilder.group({
          subTask: this.formBuilder.array([]),
      });
  }

  async addMultipleSubTask() {
      this.subtaskFormArr = this.taskDetailForm.controls['subTask'] as FormArray;
      this.subtaskFormArr.push(
          this.formBuilder.group({
              _id: [''],
              subTaskName: ['', [Validators.required]],
              subTaskDueDate: ['', [Validators.required]],
              subTaskPriority: [
                  this.priorityList.find(
                      (data: any) => data?.priority_name === priority_name.low
                  ),
              ],
              subTaskStatus: [
                  this.statusList.find(
                      (data: any) => data?.status_name === status_name.PENDING
                  ),
              ],
              subTaskAssignee: ['', [Validators.required]],
              subTaskGroup: ['', [Validators.required]],
              subTaskStartDate: [''],
          })
      );
      this.isEnableDropdown.isCheckListEnable.push(false);
      if (this.toggleValue) {
          await this.setMainTaskAssignee();
      }
      this.sendSubTaskData();
      this.taskDetailForm.updateValueAndValidity();
  }

  async deleteSubTask(index: number) {
      if (index !== -1 && !this.data?.editTaskId) {
          this.subtaskFormArr.removeAt(index);
          if (this.subtaskFormArr.length === 0) {
              this.subTaskData.emit({ validity: true });
          }   
          if (
              this.taskDetailForm.status === 'VALID' &&
        this.subtaskFormArr.length > 0
          ) {
              this.subTaskData.emit(this.subtaskFormArr.value);
          }
      } else if (this.data?.editTaskId) {
          this.subtaskFormArr.value[index]._id !== ''
              ? this.deleteTask(this.subtaskFormArr.value[index]._id, true)
              : this.subtaskFormArr.removeAt(index);
          this.subtaskFormArr.removeAt(index);
      }
      this.taskDetailForm.updateValueAndValidity();
      if (
          this.taskDetailForm.status === 'VALID' && this.subtaskFormArr.length > 0
      ) {
          this.subTaskData.emit(this.subtaskFormArr.value);
      }
      if(this.taskDetailForm.status === 'VALID' && this.subtaskFormArr?.value?.length === 0){
        this.subTaskData.emit({ validity: true });
      }
  }

  async deleteTask(taskId: any, _isSubTask: boolean) {
      (
          await this.taskService.deleteTask(
              taskId,
              this.userId,
              this.selectedCompanyId
          )
      ).subscribe((res) => {
          this.toastrService.success(res.message);
          this.taskService.getUpdateTaskAfterDeleteSubtasks(taskId)
      });
  }

  async onSelectSubTaskCheckBox(selectedId: any, index: number) {
      const myForm: any = (<FormArray>this.taskDetailForm.get('subTask')).at(
          index
      );
      if (selectedId.assignees?.length === 0 && selectedId.groups?.length === 0) {
          myForm.controls['subTaskGroup'].setValue([]);
          myForm.controls['subTaskAssignee'].setValue([]);
          myForm.controls['subTaskGroup'].setValidators(Validators.required);
          myForm.controls['subTaskAssignee'].setValidators(Validators.required);
          myForm.updateValueAndValidity();
          this.taskDetailForm.markAsDirty();
          this.sendSubTaskData();
          return;
      }

      if (selectedId.groups.length) {
          const groupSelectedId = selectedId.groups;
          const uniqueSelectedIdArr = groupSelectedId.filter(
              (ele: any, index: number) => index === groupSelectedId.indexOf(ele)
          );
          myForm.controls['subTaskGroup'].setValue([...uniqueSelectedIdArr]);
          myForm.controls['subTaskAssignee'].setValue(null);
          myForm.controls['subTaskAssignee'].clearValidators();
          myForm.controls['subTaskAssignee'].setErrors(null);
          myForm.controls['subTaskGroup'].setValidators(Validators.required);
          myForm.updateValueAndValidity();
          this.taskDetailForm.markAsDirty();
          this.sendSubTaskData();
          return;
      }

      if (selectedId.assignees.length) {
          const assigneeSelectedId = selectedId.assignees;
          const uniqueSelectedIdArr = assigneeSelectedId.filter(
              (ele: any, index: number) => index === assigneeSelectedId.indexOf(ele)
          );
          myForm.controls['subTaskAssignee'].setValue([...uniqueSelectedIdArr]);
          myForm.controls['subTaskGroup'].setValue(null);
          myForm.controls['subTaskGroup'].clearValidators();
          myForm.controls['subTaskGroup'].setErrors(null);
          myForm.controls['subTaskAssignee'].setValidators(Validators.required);
          myForm.updateValueAndValidity();
          this.taskDetailForm.markAsDirty();
          this.sendSubTaskData();
      }
  }

  setSubTaskStatus(selectedStatus: any, index: number) {
      const myForm = (<FormArray>this.taskDetailForm.get('subTask')).at(index);
      myForm.patchValue({
          subTaskStatus: selectedStatus,
      });
      this.sendSubTaskData();
  }

  setSubTaskPriority(priorityObj: any, index: number) {
      const myForm = (<FormArray>this.taskDetailForm.get('subTask')).at(index);
      myForm.patchValue({
          subTaskPriority: priorityObj,
      });
      this.sendSubTaskData();
  }

  selectedSubTaskDate(data: any, index: number) {
      const myForm = (<FormArray>this.taskDetailForm.get('subTask')).at(index);
      if (
          this.isTimeSheetSetting &&
      data.startDate !== 'Invalid Date' &&
      data.endDate !== 'Invalid Date'
      ) {
          myForm.patchValue({
              subTaskDueDate: data?.endDate?.getTime(),
              subTaskStartDate: data?.startDate?.getTime(),
          });
          data.inValidStartEndTime
              ? myForm.setErrors({ required: true })
              : myForm.setErrors(null);
          this.taskDetailForm.updateValueAndValidity();
      } else if (!this.isTimeSheetSetting) {
          myForm.patchValue({
              subTaskDueDate: data?.taskDueDate?.getTime(),
              subTaskStartDate: data?.taskstartDate?.getTime(),
          });
      }
      this.sendSubTaskData();
  }

  async setMainTaskAssignee() {
      this.subscription = this.taskService.mainAssigneeGroupIdData.subscribe(
          async (res: any) => {
              if (res) {
                  this.toggleValue = res?.toggleValue;
                  this.subtaskFormArr = this.taskDetailForm.controls[
                      'subTask'
                  ] as FormArray;
                  for (let index = 0; index < this.subtaskFormArr.length; index++) {
                      const selectedId: any = {
                          groups: [],
                          assignees: [],
                      };
                      await this.onSelectSubTaskCheckBox(selectedId, index);
                      if (this.subtaskFormArr.length > 0 && res.toggleValue) {
                          const selectedId: any = {
                              groups: res.group ? res.group : [],
                              assignees: res.assignees ? res.assignees : [],
                          };
                          await this.onSelectSubTaskCheckBox(selectedId, index);
                      } else if (!res.toggleValue) {
                          const selectedId: any = {
                              groups: [],
                              assignees: [],
                          };
                          await this.onSelectSubTaskCheckBox(selectedId, index);
                      }
                  }
              }
          }
      );
  }

  sendSubTaskData() {
    setTimeout(() => {
        if (
            this.taskDetailForm.status === 'VALID' &&
        this.subtaskFormArr.length > 0
        ) {
            this.subTaskData.emit(this.subtaskFormArr.value);
        } else {
            this.subTaskData.emit({ validity: false });
        }
    }, 500);
  }
  ngOnDestroy() {
      this.subscription?.unsubscribe();
  }
  onKeyDown(event: KeyboardEvent) {
      if (event.ctrlKey && event.key === 'Enter') {
          this.customEvent();
      }
      this.sendSubTaskData()
  }

  customEvent() {
      // Your custom event logic goes here
  }
  quickReplyCheck(event: any, modelEvent: any, index?: any) {
      this.quickReplyFilter = [];
      if (event.target.value.charAt(0) === '/') {
          if (modelEvent === 'checkList') {
              this.isEnableDropdown.isCheckListEnable[index] = true;
          }
          const quickReplyString = event.target.value
              .replace('/', '')
              .toLocaleLowerCase();
          if (quickReplyString) {
              this.quickReplyList.filter((it: any) => {
                  if (it.title) {
                      const value = it.title
                          .toLocaleLowerCase()
                          .includes(quickReplyString);
                      const hasMessage = it.message
                          .toLocaleLowerCase()
                          .includes(quickReplyString);
                      if (value || hasMessage) {
                          this.quickReplyFilter.push(it);
                      }
                  }
              });
          } else {
              this.quickReplyFilter = this.quickReplyList;
          }
      } else {
          if (modelEvent === 'checkList') {
              this.isEnableDropdown.isCheckListEnable[index] = false;
          }
      }
  }

  public handleStaticResultSelected(result: any, modelEvent: any, index?: any) {
      if (result.message) {
          if (modelEvent === 'checkList') {
              this.subtaskFormArr
                  .at(index)
                  .get('subTaskName')
                  .setValue(result.message);
              this.isEnableDropdown.isCheckListEnable[index] = false;
          }
      }
  }
  dateNow(startDate: any, endDate: any) {
      if (!endDate) {
          return;
      }
      if (endDate && !this.isTimeSheetSetting) {
          return moment(endDate).format('MMM DD,YYYY').toString();
      }
      if (endDate && this.isTimeSheetSetting) {
          return (
              moment(startDate).format('MMM DD,YYYY').toString() +
        ' - ' +
        moment(endDate).format('MMM DD,YYYY').toString()
          );
      }
  }

  async resetAssigneeOnProjectChange() {
      if (this.taskDetailForm.controls['subTask']?.value?.length) {
          await this.taskDetailForm.controls['subTask']?.value?.forEach(
              (_ele: any, index: number) => {
                  const myForm: any = (<FormArray>(
            this.taskDetailForm.get('subTask')
          )).at(index);
                  myForm.controls['subTaskGroup'].setValue([]);
                  myForm.controls['subTaskAssignee'].setValue([]);
                  myForm.controls['subTaskGroup'].setValidators(Validators.required);
                  myForm.controls['subTaskAssignee'].setValidators(Validators.required);
                  myForm.updateValueAndValidity();
                  this.taskDetailForm.markAsDirty();
              }
          );
          this.sendSubTaskData();
      }
  }

  resetSubTaskList() {
      const subTaskListArray = this.taskDetailForm.get('subTask') as FormArray;
      subTaskListArray?.clear();
  }
}
